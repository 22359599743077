<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-3">
              <v-toolbar color="orange lighten-2">
                <v-toolbar-title>Riggott's IMS - Enter Code</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="pin"
                    label="Enter the code sent to your mobile"
                    type="text"
                    v-model="pin"
                    color="orange lighten-2"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="orange lighten-2"
                  @click="tryPin">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="failed"
         :timeout="5000"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
        <v-btn
          class="text-h5"
          color="grey darken-2"
          text
          v-bind="attrs"
          @click="failed = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Login',
  data() {
    return {
      pin: '',
      failed: false,
      message: '',
    };
  },
  methods: {
    tryPin() {
      const postData = {};
      postData.pin = this.pin;
      postData.userid = this.$route.params.userid;
      axios.post('/users/resolveTwoFactor.json', postData)
        .then((response) => {
          if (response.data.result === true) {
            this.$store.dispatch('setCredentials', response.data);
            this.$router.push('/start');
          } else {
            this.failed = true;
            this.message = response.data.message;
          }
        })
        .catch(() => {
          this.message = 'Please try again.';
          this.failed = true;
        });
    },
  },
};
</script>
